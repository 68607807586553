export { WindowStatus };
//
// "use strict";


class WindowStatus {
  constructor(){
    this.scrollLock = false;
    this.scrollTop = 0;
    this.lastScrollTop = 0;
    this.viewportHeight = window.innerHeight;
  }

  scrollDown (){
    return this.scrollTop > this.lastScrollTop
  };
  scrollUp(){
    return this.scrollTop < this.lastScrollTop
  };
}


// class WindowStatus {
//   constructor() {
//     this.scrollLock = false;
//     this.scrollTop = 0;
//     this.lastScrollTop = 0;
//
//     this.viewportHeight = window.innerHeight;
//   }
//   scrollDown (){
//     return this.scrollTop > this.lastScrollTop
//   };
//   scrollUp (){
//     return this.scrollTop < this.lastScrollTop
//   };
//
// }
