// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import "./controllers"

import "./jquery"
import 'bootstrap/dist/js/bootstrap'
import 'slick-carousel'
import 'lazysizes'
import "jquery.scrollto"
import 'magnific-popup'

import "./manifests/slick"
import './manifests/site'
import './manifests/turbolink_initialize'

import './manifests/mslideout'
import './manifests/galleries'


// import './manifests/lib/lazysizes'

import './manifests/lib/baidu_tongji'
import './manifests/lib/baidu_push'
