import Waves from "./lib/waves"
// import WOW from "./lib/wow"


document.addEventListener("turbo:load", function() {
  // Force page scroll position to top at page refresh in HTML
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  // disable image save
  $('img').mousedown(function (e) {
    if(e.button == 2) { // right click
      return false; // do nothing!
    }
  })

  // hide notice after a while.
  function hideNotice(){
    if ( $('.notice').length ) {
      setTimeout(function(){
        $('.notice').alert('close');
      }, 3000);
    };
  };
  hideNotice();

  //Initialization waves effect
  // disable waves button by add .not-waves
  Waves.attach('.btn:not(.btn-flat):not(.not-waves), .btn-floating', ['waves-light']);
  Waves.attach('.btn-flat', ['waves-effect']);
  Waves.attach('.view a .mask', ['waves-light']);
  Waves.attach('.waves-light', ['waves-light']);
  Waves.attach('.navbar-nav a:not(.navbar-brand), .nav-icons li a, .navbar input, .nav-tabs .nav-item:not(.dropdown)', ['waves-light']);
  Waves.attach('.pager li a', ['waves-light']);
  Waves.attach('.pagination .page-item .page-link', ['waves-effect']);
  Waves.init();

  // delete default style for input. use: add .btn-input to .btn class.
  function btnInput(){
    var color = $('.btn-input').css('color');
    $('.btn-input').find('input').css({'background': 'rgba(0,0,0,0)', 'border': 'none', 'color': color})
  };
  btnInput();

  // << wow js
  // var wow = new WOW(
  //   {
  //     // boxClass:     'wow',      // animated element css class (default is wow)
  //     // animateClass: 'animated', // animation css class (default is animated)
  //     // offset:       0,          // distance to the element when triggering the animation (default is 0)
  //     // mobile:       true,       // trigger animations on mobile devices (default is true)
  //     // live:         true,       // act on asynchronously loaded content (default is true)
  //     // callback:     function(box) {
  //     //   // the callback is fired every time an animation is started
  //     //   // the argument that is passed in is the DOM node being animated
  //     // },
  //     // scrollContainer: null // optional scroll container selector, otherwise use window
  //   }
  // );
  // wow.init();
  // // <</ wow

  // <<scrollTo
  // gallery index scroll
  $('#index-slider-nav-scroll').on('click', function(e){
    e.preventDefault();
    // $(window).scrollTo('#index-main-container', 400, {easing: "easeInOutCubic"})
    $(window).scrollTo('#index-main-container', 400, {easing: "swing"})

  });

  // <</scrollTo

  // <<tooltip
  // $('.weixin').tooltip({title: '<%= image_tag 'qrcode_for_gh_94167f3a4733_430.jpg', width:  "130" %>', html: true, placement: 'top'});
  //
  // $('#slideout-weixin-icon').tooltip({title: '<%= image_tag 'qrcode_for_gh_94167f3a4733_430.jpg', width:  "60" %>', html: true, placement: 'top'});
  // <</tooltip

});
